import { httpService } from './http.service.js'

export const userService = {
    validateOtp,
    sendOtp,
    updateUser,
    getUserPurchases,
    getFeed,
    follow,
    unfollow,
    getCreatorFeed,
    handleLikePost,
    votePoll,
    getSubscriptions,
    getReplies,
    handleLikeComment,
    checkbeforeSubscribe,
    reply,
    cancelSubscription,
    purchaseSubscription,
    addComment,
    sendEmail,
    checkBeforePurchase,
    getUserUpcoming,
    purchaseTicket,
    getComments,
    submitDetails,
    getUser,
    getCreator,
    deleteUser,
}

async function getUser() {
    const user = await httpService.get('handle-user/get-user')
    return user
}

async function checkBeforePurchase(creatorId, showId) {
    const canPurchase = await httpService.get(`handle-user/check-before-purchase/${creatorId}/${showId}`)
    return canPurchase
}

async function validateOtp(phone, otp) {
    const user = await httpService.post('handle-user/validate-otp/' + phone, { otp })
    return user
}

async function sendOtp(phone) {
    const confirm = await httpService.get('handle-user/send-otp/' + phone)
    return confirm
}

async function getFeed(creatorsIds, search, purchasesIds = []) {
    const feed = await httpService.post('handle-user/get-feed', { creatorsIds, search, purchasesIds })
    return feed
}

async function follow(creatorId) {
    const res = await httpService.post('handle-user/follow', { creatorId })
    return res
}

async function unfollow(creatorId) {
    const res = await httpService.post('handle-user/unfollow', { creatorId })
    return res
}

async function getSubscriptions() {
    const subscriptions = await httpService.get('handle-user/get-subscriptions')
    return subscriptions
}

async function sendEmail(details) {
    const res = await httpService.post('handle-user/send-email', details)
    return res
}

async function cancelSubscription(creatorId) {
    const res = await httpService.post('handle-user/cancel-subscription', { creatorId })
    return res
}

async function updateUser(update) {
    const updatedUser = await httpService.post('handle-user/update-info', update)
    return updatedUser
}

async function getCreatorFeed(creatorId, from = 0) {
    const feed = await httpService.get(`handle-user/get-creator-feed/${creatorId}/${from}`)
    return feed
}

async function handleLikePost(creatorId, postId, like) {
    const res = await httpService.post('handle-user/handle-like-post', { creatorId, postId, like })
    return res
}

async function votePoll(postId, option) {
    const res = await httpService.post('handle-user/vote-poll', { postId, option })
    return res
}

async function addComment(comment) {
    const newComment = await httpService.post('handle-user/add-comment', { comment })
    return newComment
}

async function getReplies(commentId, from = 0) {
    const replies = await httpService.get(`handle-user/get-replies/${commentId}/${from}`)
    return replies
}

async function getComments(postId, from = 0) {
    const comments = await httpService.get(`handle-user/get-comments/${postId}/${from}`)
    return comments
}

async function reply(commentId, reply) {
    const newReply = await httpService.post('handle-user/add-reply', { commentId, reply })
    return newReply
}

async function handleLikeComment(commentId, like) {
    const confirm = await httpService.post('handle-user/handle-like-comment', { commentId, like })
    return confirm
}

async function getUserPurchases() {
    const purchases = await httpService.get('handle-user/get-purchases')
    return purchases
}

async function purchaseSubscription(creatorId, subscription) {
    const creator = await httpService.post('handle-user/subscribe', { creatorId, subscription })
    return creator
}

async function getCreator(id) {
    const creator = await httpService.get('handle-user/get-creator/' + id)
    return creator
}

async function checkbeforeSubscribe(creatorId) {
    const canPurchase = await httpService.get('handle-user/check-before-subscribe/' + creatorId)
    return canPurchase
}

async function purchaseTicket(showId, transactionDetails) {
    const miniShow = await httpService.post('handle-user/make-purchase', { showId, transactionDetails })
    return miniShow
}

async function getUserUpcoming() {
    const shows = await httpService.get('handle-user/get-user-upcoming')
    return shows
}

async function submitDetails(details) {
    const updatedUser = await httpService.put('handle-user/submit-details', details)
    return updatedUser
}

async function deleteUser(phone, otp) {
    const res = await httpService.delete('handle-user/delete-user', { phone, otp })
    return res
}
