import { useEffect, useState } from "react"
import { useNavigate } from "react-router"
import { useSelector } from "react-redux"
import { userService } from '../services/userService.js'
import { Stream } from './stream.jsx'
import { ErrorServer } from './error-server.jsx'
import Timer from "../cmps/timer.jsx"
const AgoraRTC = require('agora-rtc-sdk-ng')
const client = AgoraRTC.createClient({ mode: "live", codec: "vp8" })
AgoraRTC.setLogLevel(2)

export function Tickets() {
    const navigate = useNavigate()
    const user = useSelector((state) => state.user)
    const { returnToTickets } = useSelector((storeState) => storeState.generalModule)
    const { animations } = useSelector((storeState) => storeState.generalModule)
    const [tickets, setTickets] = useState(null)
    const [error, setError] = useState(false)
    const [show, setShow] = useState(null)

    useEffect(() => {
        document.title = 'PikmeTV | Watch live stream'
        if (!user.nickName) navigate('/login')
        else loadEssentials()
    }, [])

    useEffect(() => {
        if (show !== null) setShow(null)
    }, [returnToTickets])

    const loadEssentials = async () => {
        try {
            const loadedshows = await userService.getUserUpcoming()
            loadedshows.sort((a, b) => new Date(a.date) - new Date(b.date))
            setTickets(loadedshows)
            if (loadedshows.length === 1) setShow(loadedshows[0])
        }
        catch {
            setError(true)
        }
    }

    if (error) return <ErrorServer />

    if (!tickets) return <div className="home"><div className="loader"><div></div><div></div><div></div><div></div>
        <div></div><div></div><div></div><div></div></div></div>

    if (tickets.length === 0) return <div className="no-tickets-wrapper">
        <div className="no-tickets">
            <img className="no-history" src={require('../style/imgs/no-results.webp')} alt='no history' />
            <p>לא מצאנו כרטיסים בבעלותך להופעה שעומדת להתחיל.</p>
            <p onClick={() => navigate('/')} className='clickable main-color'>לכל ההופעות</p>
        </div>
    </div>

    if (!show) return <div className="tickets-container" >
        {tickets.map((show, idx) =>
            <div className="ticket-card" key={idx} onClick={() => setShow(show)}>
                <img src={show.img} alt='show' />
                <p>{show.title}</p>
                <a className="undecorate"><Timer showDate={new Date(show.date)} mode={'card'} glow={animations} /></a>
            </div>)}
    </div>

    return <Stream show={show} user={user} client={client} />
}