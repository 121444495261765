import { useState, useRef, useEffect } from "react"
import { useNavigate } from "react-router"
import { useDispatch, useSelector } from "react-redux"
import { userService } from "../services/userService"
import { login } from "../store/reducers/userReducer"
import { setUpperPopup } from "../store/actions/general.actions"
import { Error } from "../pages/error"
import { countryList } from "../services/data.service"
import { setPopup } from "../store/actions/general.actions"

export function Login({ component = false, callback = null }) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [phone, setPhone] = useState(null)
    const [code, setCode] = useState({ 0: '', 1: '', 2: '', 3: '', 4: '', 5: '' })
    const { callbackLink } = useSelector(state => state.generalModule)
    const countryRef = useRef()
    const phoneRef = useRef()

    useEffect(() => {
        if (Object.values(code).every(val => val !== '')) submitCode(false)
    }, [code])

    const handlePhone = async (ev) => {
        if (ev) ev.preventDefault()
        const formatted = countryRef.current.value + (phoneRef.current.value[0] == 0 ? phoneRef.current.value.slice(1) : phoneRef.current.value)
        try {
            const confirm = await userService.sendOtp(Number(formatted))
            if (confirm.name === 'AxiosError') dispatch(setUpperPopup('errorServer'))
            else if (confirm.response) setPhone(formatted)
            else dispatch(setUpperPopup('invalidPhone'))
        }
        catch {
            dispatch(setUpperPopup('errorServer'))
        }
    }

    const submitCode = async (ev) => {
        if (ev) ev.preventDefault()
        const formatted = code[0] + code[1] + code[2] + code[3] + code[4] + code[5]
        try {
            const loadedUser = await userService.validateOtp(phone, formatted)
            if (typeof loadedUser === 'string') return dispatch(setUpperPopup('errorCode'))
            else if (typeof loadedUser === 'object' && loadedUser.nickName) {
                dispatch(login(loadedUser))
                if (loadedUser.default) dispatch(setPopup('edit-profile'))
                else if (component) return callback()
                const link = callbackLink ? callbackLink.split("#")[1] : '/profile'
                navigate(link)
            }
        }
        catch {
            dispatch(setUpperPopup('errorServer'))
        }
    }

    const handleCode = (ev) => {
        const { value, name } = ev.target
        const el = document.getElementById(0)

        if (value && value.length === 6) {
            setCode({ 0: value[0], 1: value[1], 2: value[2], 3: value[3], 4: value[4], 5: value[5] })
            document.getElementById(5).focus()
            return
        }

        for (let i = 0; i < Number(name); i++) if (code[i] === '') {
            const el = document.getElementById(i)
            el.focus()
            return
        }

        if ((Number(value) >= 0 && Number(value) <= 9)) {
            if (value !== '' && name !== '5') {
                const el = document.getElementById(Number(name) + 1)
                console.log(el)
                el.focus()
            } 
            setCode({ ...code, [name]: value })
        }
    }

    const onDelete = (ev) => {
        const { key, target } = ev
        if (key === 'Backspace' && target.value === '' && target.name !== '0') document.getElementById(Number(target.name) - 1).focus()
    }

    const slots = [0, 1, 2, 3, 4, 5]

    try {
        return <section className={component ? 'login cmp-login' : 'login'}>
            <h1>התחבר</h1>
            {!phone && <form onSubmit={handlePhone}>
                <div className="phone-wrapper ltr">
                    <select ref={countryRef} >
                        {countryList.map(country => <option key={country.country} value={country.code}>{country.iso} +{country.code}</option>)}
                    </select>
                    <input type='number' ref={phoneRef} placeholder='05...' required />
                </div>
                <h5>קוד בעל 6 ספרות ישלח אליך בדקות הקרובות.</h5>
                <button>שלח</button>
            </form>}
            {phone && <>
                <h5 style={{ marginBottom: '8px' }}>הקלד את הקוד שקיבלת : </h5>
                <p style={{ opacity: '1' }}>{phone}</p>
                <form className="code ltr" onSubmit={submitCode}>
                    {slots.map(num => <input key={num} autoFocus={num === 0 ? true : false} id={num} type="number" name={num} className="digit" value={code[num]} onChange={handleCode} onKeyDown={onDelete} />)}
                </form>
                <h4 onClick={() => { setPhone(null); setCode({ 0: '', 1: '', 2: '', 3: '', 4: '', 5: '' }) }}><span className="material-symbols-outlined mirror">chevron_left</span>חזור</h4>
                <button onClick={submitCode}>המשך</button>
            </>}
        </section>
    }
    catch {
        return <Error />
    }
}