import { useState } from "react"
import { Error } from "../pages/error"
import { userService } from "../services/userService"
import { SubscriptionCard } from "./subscription-card"

export function Subscriptions({ user }) {
    const [subscriptions, setSubscriptions] = useState(null)

    const loadSubscriptions = async () => {
        try {
            setSubscriptions('loading')
            const loadedSubscriptions = await userService.getSubscriptions()
            console.log(loadedSubscriptions)
            setSubscriptions(loadedSubscriptions)
        }
        catch (err) {
            console.log(err)
            setSubscriptions('error')
        }
    }

    if (!subscriptions) return <div className="show-subscriptions" onClick={loadSubscriptions}><p>מנויים</p><span className="material-symbols-outlined">keyboard_arrow_down</span></div>

    if (subscriptions === 'error') return <Error />

    if (subscriptions === 'loading') return <div className="loader loader-block"><div></div><div></div><div></div><div></div>
        <div></div><div></div><div></div><div></div></div>

    if (subscriptions.length === 0) return <p>לא נמצאו מנויים.</p>

    return <div className="subs-container">
        {subscriptions.map((subscription, idx) => <SubscriptionCard key={idx} subscription={subscription} />)}
    </div>
}