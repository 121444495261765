export function makeCommas(price) {
  return Math.floor((price)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export function formatHour(date) {
  try {
    const objDate = new Date(date)
    return objDate.getHours() + ':' + (objDate.getMinutes() > 9 ? objDate.getMinutes() : '0' + objDate.getMinutes())
  }
  catch {
    return '--'
  }

}

export function getDateName(date, language = 'en') {
  try {
    let monthNames = language === 'en' ? ["JAN", "FEB", "MAR", "APR", "MAY", "JUN",
      "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"] : ["ינו", "פבר", "מרץ", "אפר", "מאי", "יונ",
      "יול", "אוג", "ספט", "אוק", "נוב", "דצמ"]
    const objDate = new Date(date)
    return (monthNames[objDate.getMonth()] + ' ' + objDate.getDate())
  }
  catch {
    return '--'
  }
}

export function formatDateHour(date) {
  const d = new Date(date)
  return `${make2digits(d.getDate())}/${make2digits(d.getMonth() + 1)}/${d.getFullYear()}  ${make2digits(d.getHours())}:${make2digits(d.getMinutes())}`
}

export function formatDate(date) {
  if (!date) return '...'
  const d = new Date(date)
  return `${make2digits(d.getDate())}/${make2digits(d.getMonth() + 1)}/${d.getFullYear()}`
}

export function make2digits(num) {
  if (num < 10) return '0' + num.toString()
  return num
}

export function getkHowLongAgo(date) {
  const now = new Date()
  const diff = now - new Date(date) // Difference in milliseconds
  const msInHour = 1000 * 60 * 60
  const msInDay = msInHour * 24
  const msInWeek = msInDay * 7
  const msInMonth = msInDay * 30
  const msInYear = msInDay * 365

  if (diff >= msInYear) {
    return 'יותר משנה'
  } else if (diff >= msInMonth) {
    const monthsAgo = Math.floor(diff / msInMonth)
    return `${monthsAgo} ${monthsAgo > 1 ? 'חודשים' : 'חודש'}`
  } else if (diff >= msInWeek) {
    const weeksAgo = Math.floor(diff / msInWeek)
    return `${weeksAgo} ${weeksAgo > 1 ? 'שבועות' : 'שבוע'}`
  } else if (diff >= msInDay) {
    const daysAgo = Math.floor(diff / msInDay)
    return `${daysAgo} ${daysAgo > 1 ? 'ימים' : 'יום'}`
  } else if (diff >= msInHour) {
    const hoursAgo = Math.floor(diff / msInHour)
    return `${hoursAgo} ${hoursAgo > 1 ? 'שעות' : 'שעה'}`
  } else {
    return 'פחות משעה'
  }
}

export function getSocialIcon(link) {
  if (!link) return 'instagram'
  if (link.includes('twitter')) return 'twitter'
  if (link.includes('tiktok')) return 'tiktok'
  if (link.includes('youtube')) return 'youtube'
  return 'instagram'
}

export function putKandM(num) {
  if (typeof num !== 'number') return 'To be added'
  if (num < 999) return num
  if (num < 100000) return makeCommas(num)
  if (num < 1000000) return (num / 1000).toFixed(2) + 'K'
  return (num / 1000000).toFixed(2) + 'M'
}

export function getTimeUntil(date) {
  const now = new Date()
  const then = new Date(date)
  const diff = then - now
  const days = Math.floor(diff / 1000 / 60 / 60 / 24)
  const hours = Math.floor(diff / 1000 / 60 / 60) % 24
  if (days > 0) return `${days} ימים`
  else if (hours > 1) return `${hours} שעות`
  else return false
}