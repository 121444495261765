import Axios from 'axios'

const BASE_URL = process.env.NODE_ENV === 'production'
    ? 'https://pikme.tv/'
    : 'http://localhost:3030/'

var axios = Axios.create({
    withCredentials: true
})

async function test() {
    try {
        const res = await axios({
            url: `http://pik10-env.eba-3bxci3up.eu-central-1.elasticbeanstalk.com/handle-user/send-otp/972528087084`,
            method: 'GET',

        })
        console.log(res)
    }
    catch (err) {
        console.log(err)
    }
}

export const httpService = {
    get(endpoint, data) {
        return ajax(endpoint, 'GET', data)
    },
    post(endpoint, data) {
        return ajax(endpoint, 'POST', data)
    },
    put(endpoint, data) {
        return ajax(endpoint, 'PUT', data)
    },
    delete(endpoint, data) {
        return ajax(endpoint, 'DELETE', data)
    },
    test
}

async function ajax(endpoint, method = 'GET', data = null) {
    try {
        const res = await axios({
            url: `${BASE_URL}${endpoint}`,
            method,
            data,
            params: (method === 'GET') ? data : null
        })
        return res.data
    }
    catch (err) {
        throw err
    }
}