import { useNavigate } from 'react-router'
import { putKandM } from '../services/utils'

export function CreatorCard({ creator }) {
    const navigate = useNavigate()
    return <section className='creator-card' onClick={() => navigate(`/creator/${creator._id}`)}>
        <img src={creator.image} />
        <div className="creator-card-details">
            <h3>{creator.nickName}</h3>
            <p>{creator.description}</p>
            <div>
                <span className="material-symbols-outlined">groups</span>
                <p> {putKandM(creator.followersAmount)} עוקבים</p>
            </div>
            <div className='social'>
                {creator.socialLinks.youtube && <a href={creator.socialLinks.youtube} target="_blank" rel="noreferrer"><img src={require('../style/imgs/contact/youtube.webp')} /></a>}
                {creator.socialLinks.instagram && <a href={creator.socialLinks.instagram} target="_blank" rel="noreferrer"><img src={require('../style/imgs/contact/instagram.webp')} /></a>}
                {creator.socialLinks.tiktok && <a href={creator.socialLinks.tiktok} target="_blank" rel="noreferrer"><img src={require('../style/imgs/contact/tiktok.webp')} /></a>}
            </div>
        </div>
    </section>
}