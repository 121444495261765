export function turnDark() {
    return (dispatch) => {
        dispatch({ type: 'TURN_DARK' })
    }
}

export function turnLight() {
    return (dispatch) => {
        dispatch({ type: 'TURN_LIGHT' })
    }
}
export function setViewers(viewers) {
    return (dispatch) => {
        dispatch({ type: 'SET_VIEWERS', viewers })
    }
}
export function setFilter(filter) {
    return (dispatch) => {
        dispatch({ type: 'SET_FILTER', filter })
    }
}

export function setMenu(menu) {
    return (dispatch) => {
        dispatch({ type: 'SET_MENU', menu })
    }
}
export function setPopup(popup) {
    return (dispatch) => {
        dispatch({ type: 'SET_POPUP', popup })
    }
}

export function setPopupCallback(popupCallback) {
    return (dispatch) => {
        dispatch({ type: 'SET_POPUP_CALLBACK', popupCallback })
    }
}

export function setAnimations(animations = null) {
    if (animations === null) return (dispatch) => {
        dispatch({ type: 'TOGGLE_ANIMATIONS' })
    }
    return (dispatch) => {
        dispatch({ type: 'SET_ANIMATIONS', animations })
    }
}

export function setPopupInfo(popupInfo) {
    return (dispatch) => {
        dispatch({ type: 'SET_POPUP_INFO', popupInfo })
    }
}
export function setPopupPay(popupPay) {
    return (dispatch) => {
        dispatch({ type: 'SET_POPUP_PAY', popupPay })
    }
}

export function toggleMenu() {
    return (dispatch) => {
        dispatch({ type: 'TOGGLE_MENU' })
    }
}

export function setReturnToTickets(returnToTickets) {
    return (dispatch) => {
        dispatch({ type: 'SET_RETURN_TO_TICKETS', returnToTickets })
    }
}

export function setUpperPopup(upperPopup) {
    return (dispatch) => {
        dispatch({ type: 'SET_UPPER_POPUP', upperPopup })
    }
}

export function setPopupUpcoming(popupUpcoming) {
    return (dispatch) => {
        dispatch({ type: 'SET_POPUP_UPCOMING', popupUpcoming })
    }
}

export function setFlashUpcoming(flashUpcoming) {
    return (dispatch) => {
        dispatch({ type: 'SET_FLASH_UPCOMING', flashUpcoming })
    }
}

export function resetGeneralState() {
    return (dispatch) => {
        dispatch({ type: 'RESET_GENERAL_STATE' })
    }
}

export function setCallbackLink(callbackLink) {
    return (dispatch) => {
        dispatch({ type: 'SET_CALLBACK_LINK', callbackLink })
    }
}