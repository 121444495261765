import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { setPopup, setUpperPopup } from '../../store/actions/general.actions'
import { addSubscription } from '../../store/reducers/userReducer'
import { userService } from '../../services/userService'
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js"

export function Premium({ creator, user }) {
    const [paymentOpen, setPaymentOpen] = useState(false)
    const dispatch = useDispatch()

    const initialOptions = {
        "client-id": "AaOFwXiwBx9iEvRbVtabFv-lIAnNti3w-8Hj2FLx0kvi0rGf9YOdoNgxm0iE80emr-hDU3JrPjt_KgV2",
        "data-sdk-integration-source": "integrationbuilder_sc",
        currency: "ILS",
        locale: 'he_IL',
        vault: true
    }

    const checkbeforeSubscribe = async () => {
        if (!user.phone) return dispatch(setPopup('login'))
        try {
            const canPurchase = await userService.checkbeforeSubscribe(creator._id)
            if (!canPurchase) dispatch(setUpperPopup('creator-banned-you'))
            setPaymentOpen(true)
        }
        catch {
            dispatch(setUpperPopup('errorServer'))
        }
    }

    const onApprove = async (data) => {
        try {
            const subscription = {
                subscriptionId: data.subscriptionID,
                planId: creator.subscription.paypalPlanId || '',
                paymentProvider: 'paypal',
                status: 'active',
                price: creator.subscription.price,
                subscribedAt: new Date().toISOString(),
            }
            const creatorId = await userService.purchaseSubscription(creator._id, subscription)
            dispatch(addSubscription(creatorId))
        }
        catch (err) {
            console.log(err)
            dispatch(setPopup('errorServer'))
        }
    }

    const { subscription } = creator

    return <div className="premium">
        <div className='inner'>
            <h1>הצטרף כמנוי ל {creator.nickName}.</h1>
            <p>{subscription?.description}</p>
            <p className='posts'>{subscription.subscribersAmount} מנויים ∙ {subscription.postsAmount} פוסטים</p>
            {paymentOpen ? <PayPalScriptProvider options={initialOptions}>
                <PayPalButtons
                    style={{ layout: 'vertical', color: 'black',shape:'pill' }}
                    createSubscription={(data, actions) => {
                        return actions.subscription.create({
                            plan_id: 'P-7MT642241G655293EM3ZJ67Q',
                        })
                    }}
                    onApprove={onApprove}
                />
            </PayPalScriptProvider> : <>
            <div className='subscribe' onClick={checkbeforeSubscribe}>{subscription?.price + '₪'}/לחודש</div>
            </> }
            <p className='info'> *חיוב בסך ₪ {subscription.price} יתבצע בחשבונך בכל חודש החל מתאריך הרכישה, תוכל להנות מתכנים בלעדיים של {creator.nickName}.</p>
        </div>
    </div>
}