import { useState } from "react"
import { CreatorCard } from "./creator-card"
import { userService } from "../services/userService"
import { useDispatch } from "react-redux"
import { removeSubscription } from "../store/reducers/userReducer"
import { formatDate } from "../services/utils"
import { setUpperPopup } from "../store/actions/general.actions"

const tr = {
    'active': 'פעיל',
    'canceled': 'מבוטל',
    'paused': 'מושהה',
}

export function SubscriptionCard({ subscription }) {
    const dispatch = useDispatch()
    const [isCreator, setIsCreator] = useState(true)
    const [cancel, setCancel] = useState(false)

    const cancelSubscription = async () => {
        try {
            await userService.cancelSubscription(subscription._id)
            dispatch(removeSubscription(subscription._id))
            dispatch(setUpperPopup('subscription-cancelled'))
            setIsCreator('deleted')
        }
        catch (err) {
            console.log(err)
        }
    }

    if (isCreator === 'deleted') return <></>

    return <div className="sub-wrapper">
        {isCreator ? <CreatorCard creator={subscription} /> :
            <div className="creator-card default-cursor">
                <img src={subscription.image} />
                <div className="creator-card-details">
                    <h3>{subscription.nickName}</h3>
                    <p>מחיר חודשי : {subscription.price}₪</p>
                    <p>מנוי מ: {formatDate(subscription.subscribedAt)}</p>
                    <p>סטאטוס : {tr[subscription.status]}</p>
                    {cancel ? <div style={{width:'100%',justifyContent:'space-between'}}>
                        <p>ביטול מנוי?</p>
                        <p className="cancel" onClick={cancelSubscription}>ביטול</p>
                        <p className="clickable main-color" onClick={() => setCancel(false)}>חזור</p>
                    </div> :
                        <p className="cancel" onClick={() => setCancel(true)}>ביטול מנוי</p>}
                </div>
            </div>}
        <span onClick={() => setIsCreator(!isCreator)} className="material-symbols-outlined change-sub">change_circle</span>
    </div>
}