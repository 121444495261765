import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setUpperPopup } from "../store/actions/general.actions"

export function UpperPopup() {
    const dispatch = useDispatch()
    const { upperPopup } = useSelector((storeState) => storeState.generalModule)

    useEffect(() => {
        if (upperPopup) reset()
    }, [upperPopup])

    const reset = () => {
        setTimeout(() => { dispatch(setUpperPopup('')) }, 4000)
    }

    if (!upperPopup) return <></>

    return (
        <section className='upper-popup'>
            {upperPopup === 'imageSize' && <p>גודל התמונה יכול להיות עד 10MB</p>}
            {upperPopup === 'copied' && <p>לינק הועתק</p>}
            {upperPopup === 'creator-banned-you' && <p>יוצר התוכן הזה חסם אותך ולכן לא תוכל לקנות כרטיסים לאירועים שלו.</p>}
            {upperPopup === 'empty-nickname' && <p>אנא הקלד שם בעל 2 אותיות לפחות</p>}
            {upperPopup === 'errorCode' && <p>הקוד אינו נכון</p>}
            {upperPopup === 'errorServer' && <p>משהו השתבש, אנא נסה שנית במועד מאוחר יותר</p>}
            {upperPopup === 'logout' && <p>התנתקת בהצלחה</p>}
            {upperPopup === 'subscription-cancelled' && <p>המנוי בוטל</p>}
            {upperPopup === 'email-sent' && <p>המייל נשלח בהצלחה</p>}
            {upperPopup === 'missing-details' && <p>אנא מלאו את כל הפרטים</p>}
            {upperPopup === 'max-interests' && <p>ניתן למלא עד 5 תחביבים</p>}
            {upperPopup === 'email-registered' && <p>קיבלנו! ניצור קשר בהמשך.</p>}
            {upperPopup === 'alreadyPurchased' && <p>כבר רכשת כרטיס להופעה הזו</p>}
        </section>
    )
}