import { useDispatch } from "react-redux"
import { useNavigate } from "react-router"
import { logout } from "../store/reducers/userReducer"
import { httpService } from "../services/http.service"

export function ErrorServer() {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const resetWebsite = () => {
        dispatch(logout())
        navigate('/profile')
        window.location.reload()
    }

    const test = async () => {
        try {
            const r = await httpService.test()
            console.log(r)
        }
        catch (err) {
            console.log(err)
        }
    }

    return <div className='error'>
        <img src={require('../style/imgs/error-server.webp')} alt='error' />
        <h1>העמוד לא ניתן להצגה כרגע...</h1>
        <p>משהו השתבש.</p>
        <p>נסה להתחבר מחדש או צור קשר עם שירותי התמיכה.</p>
        <div onClick={resetWebsite}>התחבר</div>
    </div>
}