import { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router"
import { showService } from "../../services/showService"
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js"
import { setPopup, setUpperPopup, setPopupPay } from "../../store/actions/general.actions"
import { addPurchase } from "../../store/reducers/userReducer"
import { userService } from "../../services/userService"

const initialOptions = {
    "client-id": "AaOFwXiwBx9iEvRbVtabFv-lIAnNti3w-8Hj2FLx0kvi0rGf9YOdoNgxm0iE80emr-hDU3JrPjt_KgV2",
    "enable-funding": "",
    "disable-funding": "",
    "data-sdk-integration-source": "integrationbuilder_sc",
    currency: "ILS",
    locale: 'he_IL'
}

export function PopupPay() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const show = useSelector((storeState) => storeState.generalModule.popupPay)
    const [state, setState] = useState("pay")

    useEffect(() => {
        checkBeforePurchase()
        return () => {
            dispatch(setPopupPay(''))
        }
    }, [])

    const createOrder = async () => {
        try {
            const orderData = await showService.makeOrder(show._id)
            if (orderData.id) return orderData.id
            else {
                const errorDetail = orderData?.details?.[0]
                const errorMessage = errorDetail ? `${errorDetail.issue} ${errorDetail.description} (${orderData.debug_id})` : JSON.stringify(orderData)
                throw new Error(errorMessage)
            }
        }
        catch (error) {
            console.log(error)
            setState("failed")
        }
    }

    const onApprove = async (data, actions) => {
        try {
            const t = await actions.order.capture()
            const transaction = {
                paymentProvider: 'paypal',
                transactionId: t.purchase_units[0].payments?.captures[0].id || t.payments?.captures[0].id,
                payerEmail: t.payer?.email_address || null,
                payerName: t.payer.name?.given_name + ' ' + t.payer?.name?.surname || 'Guest',
                amount: t.purchase_units[0].amount?.value,
                currency: t.purchase_units[0].amount?.currency_code,
            }
            alert(transaction.transactionId)
            const miniShow = await userService.purchaseTicket(show._id, transaction)
            dispatch(addPurchase(miniShow))
            setState("success")
        }
        catch (err) {
            alert(err.message)
            setState("failed")
        }
    }

    const checkBeforePurchase = async () => {
        try {
            const canPurchase = await userService.checkBeforePurchase(show.miniCreator.id, show._id)
            if (!canPurchase) {
                dispatch(setPopup(''))
                return dispatch(setUpperPopup('creator-banned-you'))
            }
        }
        catch {
            dispatch(setPopup(''))
            return dispatch(setUpperPopup('errorServer'))
        }
    }

    // const test = async () => {
    //     try {
    //         const transaction = {
    //             paymentProvider: 'paypal',
    //             transactionId: 'test',
    //             payerEmail: 'test',
    //             payerName: 'Guest',
    //             amount: 5,
    //             currency: 'ILS',
    //         }
    //         const miniShow = await userService.purchaseTicket(show._id, transaction)
    //         dispatch(addPurchase(miniShow))
    //         setState("success")
    //     }
    //     catch (err) {
    //         console.log(err)
    //         setState("failed")
    //     }
    // }

    return <div className="popup-pay">
        {state === 'pay' && <div className="paypal">
            <p>לתשלום : ₪<span>{show.price} </span></p>
            <p><span className="material-symbols-outlined align" style={{ paddingTop: '3px' }}>lock</span>כל התשלומים מבוצעים ומאובטחים באמצעות Paypal.</p>
            <PayPalScriptProvider options={initialOptions}>
                <PayPalButtons style={{ shape: "rect", layout: "vertical", color: 'black' }} createOrder={createOrder} onApprove={onApprove} />
            </PayPalScriptProvider>
            {/* <button onClick={test}>test</button> */}
        </div>}
        {state === 'success' && <div className="success">
            <img src={show.img} alt='show' />
            <p>כרטיסים בעבור {show.title} נרכשו בהצלחה</p>
            <div className="buttons-wrapper" style={{ marginTop: '10px' }}>
                <div className="lighter" style={{ borderWidth: 0 }} onClick={() => dispatch(setPopup(''))}>סגור</div>
                <div className="bolder" onClick={() => { dispatch(setPopup('')); navigate('/tickets') }}>כרטיסים</div>
            </div>
        </div>}
        {state === 'failed' && <div className="center">
            <img src={require('../../style/imgs/error.webp')} alt='error' />
            <p style={{ marginTop: '10px' }}>לצערינו לא יכולנו לבצע את התשלום, אנא נסה שוב במועד מאוחר יותר.</p>
        </div>}
        <span onClick={() => dispatch(setPopup(''))} className="material-symbols-outlined exit clickable">cancel</span>
    </div>
}