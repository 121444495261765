import { useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { setUpperPopup, setPopup } from '../../store/actions/general.actions'
import { userService } from '../../services/userService'
import { setNickName, setImage } from '../../store/reducers/userReducer'
import { uploadService } from '../../services/upload.service'

export function EditProfile() {
    const dispatch = useDispatch()
    const nameRef = useRef()
    const imageRef = useRef()
    const [image, setImageLocal] = useState(null)
    const [loader, setLoader] = useState(false)

    const onImageChange = async (ev) => {
        try {
            if (!ev.target.files[0]) return
            if (!ev.target.files[0].type.startsWith('image/')) return dispatch(setUpperPopup('image-only'))
            setImageLocal(URL.createObjectURL(ev.target.files[0]))
        }
        catch (err) {
            dispatch(setUpperPopup('errorServer'))
        }
    }

    const changeDetails = async () => {
        try {
            const nickName = nameRef.current.value
            const image = imageRef.current.files[0]
            if (!nickName || nickName.length < 2) return dispatch(setUpperPopup('empty-nickname'))
            let change = { nickName }
            setLoader(true)
            if (image) {
                if (image.size > 10_000_000) return dispatch(setUpperPopup('imageSize'))
                const { secure_url } = await uploadService.uploadImg(image)
                change.image = secure_url
            }
            const user = await userService.updateUser(change)
            dispatch(setNickName(user.nickName))
            if (user.image) dispatch(setImage(user.image))
            dispatch(setPopup(''))
        }
        catch (err) {
            setLoader(false)
            dispatch(setUpperPopup('errorServer'))
        }
    }

    return <section className='popup-edit-profile'>
        <p>בחרו שם משתמש ותמונה חדשים, תוכלו לשנות אותם בהמשך:</p>
        <input type="text" maxLength={15} ref={nameRef} placeholder="שם משתמש" />
        <input type="file" ref={imageRef} onChange={onImageChange} className='non-appear' id='image123' />
        <label htmlFor='image123'>
            <img src={image || 'https://images.unsplash.com/photo-1561211919-1947abbbb35b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8M3x8YWJzdHJhY3QlMjBibHVlfGVufDB8fDB8fA%3D%3D&w=1000&q=80'} alt="profile image" />
        </label>
        {loader ?
            <div className="loader loader-block"><div></div><div></div><div></div><div></div>
                <div></div><div></div><div></div><div></div></div> :
            <div className='buttons-wrapper'>
                <button onClick={changeDetails} className='bolder'>שמור</button>
                <button onClick={() => dispatch(setPopup(''))} className='lighter'>ביטול</button>
            </div>}
    </section>
}